import React, {useState, useRef} from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Seo from '../components/global/Seo'

export default function Events({data}) {
  const page = data.wpEvent
  
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [guest, setGuests] = useState()
  const [event, setEvent] = useState()
  const [notification, setNotification] = useState('')
  const nameRef = useRef()
  const emailRef = useRef()
  const phoneRef = useRef()
  const guestsRef = useRef()
  const eventRef = useRef()
  
  const handleOnChange = (e) => {
    const target = e.target.id
    const value = e.target.value
    
    if(target === 'name'){
      setName(value)
    }
    if(target === 'email'){
      setEmail(value)
    }
    if(target === 'phone'){
      setPhone(value)
    }
    if(target === 'guests'){
      setGuests(value)
    }
    setEvent(eventRef.current.value)
  }

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  const resetFields = () => {
    nameRef.current.value = ''
    emailRef.current.value = ''
    phoneRef.current.value = ''
    guestsRef.current.value = ''
  }

  const displayNotification = () => {
    setNotification('Thanks for signing up to the event. ')
    setTimeout(() => {setNotification('')}, 7000)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log({
    //   "form-name": e.target.getAttribute("name"),
    //   "name": name,
    //   "email": email,
    //   "phone": phone,
    //   "guests": guest,
    //   "event": event,
    // })
    console.log(encode({
      "form-name": e.target.getAttribute("name"),
      "name": name,
      "email": email,
      "phone": phone,
      "guests": guest,
      "event": event,
    })
    )
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": e.target.getAttribute("name"),
        "name": name,
        "email": email,
        "phone": phone,
        "guests": guest,
        "event": event,
      }),
    })
      .then(() => {
        resetFields()
        displayNotification()
      })
      .catch((error) => alert(error));
  };

  const date = page.acfEvents.date.split(/\//)
  const endDate = page?.acfEvents?.endDate?.split(/\//)
  return (
    <div className='single-events '>
      <Seo title={`Events | ${page.title}`} description="With origins dating from 1874, the distinguished Manchester Tennis &amp; Racquet Club (MTRC) has been situated on Blackfriars Road in Salford since 1880. This truly unique members' club is one of Manchester's greatest hidden treasures."  />
      <div className="content">
        <h1 className='tite'>{page.title}</h1>
        <div className="date">Event Date: {[ date[1], date[0], date[2] ].join('/')} {endDate ? ` - ${[ endDate[1], endDate[0], endDate[2] ].join('/')}` : '' } </div>
        <div className="text">{page.content ? parse(page.content) : ''}</div>
        <div className='instruction'><strong>To sign up to this event, please fill in and submit the form below.</strong></div>
        <div className='instruction'>Some events may have restricted numbers. If the event is fully booked we will be in touch to let you know.</div>
      </div>
      <div className={`form-wrapper`}>
        <form  method="POST" data-netlify="true" name="signup-for-event" onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value="signup-for-event" />
          <div className='input-wrap'>
            <label htmlFor="#name">Name</label>
            <input onKeyUp={(e) => handleOnChange(e)} ref={nameRef} type="text" required name="name" id='name' placeholder='Your Name'/>
          </div>
          <div className='input-wrap'>
            <label htmlFor="email">Email</label>
            <input onKeyUp={(e) => handleOnChange(e)} ref={emailRef} type="email" required name="email" id='email' placeholder='Your Email'/>
          </div>
          <div className='input-wrap'>
            <label htmlFor="#phone">Phone</label>
            <input onKeyUp={(e) => handleOnChange(e)} ref={phoneRef} type="text" required name="phone" id='phone' placeholder='Your Phone number'/>
          </div>
          <div className='input-wrap'>
            <label htmlFor="#guests">Number of people (please include yourself and any guests)</label>
            <input onKeyUp={(e) => handleOnChange(e)} ref={guestsRef} type="text" required name="guests" id='guests' placeholder='Number of Guests'/>
          </div>
          <div className='input-wrap '>
            <label htmlFor="#event">Event</label>
            <input type="text" name="event"  ref={eventRef}  disabled id='event' value={page.title}/>
          </div>
          <div className='input-wrap'>
            <input type="submit" value="Sign up" />
          </div>
          {
            notification !== '' ?
              <div className="input-wrap heading-7 red" style={{color:'red'}}>
                {notification}
              </div>
            : ''
          }
        </form>
      </div>
    </div>
  )
}


export const query = graphql`
  query EventQuery($id: String) {
    wpEvent(id: {eq: $id}) {
      title
      content
      acfEvents{
        date
        endDate
      }
    }
  }
`